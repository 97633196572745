/* =========================================
      Utils
-------------------------------------- */
* {
    box-sizing: border-box;

    font-family: helvetica neue, helvetica, sans-serif;
}

blink-animation

.hidden,
[hidden] {
    display: none !important;
}

.disabled,
[disabled] {
    opacity: 0.5 !important;
    pointer-events: none !important;
}

.blink {
    animation: blink-animation 1s steps(5, start) infinite;
}

.blinkSlow {
    animation: blink-animation-2 2s steps(5, start) infinite;
}

@keyframes blink-animation {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes blink-animation-2 {
    from {
        opacity: 1;
    }
    to {
        opacity: 0.5;
    }
}

/* =========================================
      Native Overrides
-------------------------------------- */

video::-webkit-media-controls-overlay-play-button {
    display: none;
}

/* ::-webkit-media-controls-enclosure {
    z-index: 2147483647 !important;
}
::-moz-media-controls-enclosure {
    z-index: 2147483647 !important;
}
::-ie-media-controls-enclosure {
    z-index: 2147483647 !important;
}
::-o-media-controls-enclosure {
    z-index: 2147483647 !important;
}
::media-controls-enclosure {
    z-index: 2147483647 !important;
}

.jw-controls {
    z-index: 2147483647 !important;
}

.vjs-control-bar {
    z-index: 2147483647 !important;
}

video::-webkit-media-controls {
    z-index: 2147483647 !important;
} */

/* video::-webkit-media-controls-overlay-play-button {
    pointer-events: all;
}

video::-webkit-media-controls-timeline {
    pointer-events: all;
}

video::-internal-media-controls-button-panel {
    pointer-events: all !important;
}

video::-internal-media-controls-text-track-list {
    pointer-events: all;
}

video::-internal-media-controls-overflow-menu-list {
    pointer-events: all;
}
*/

header {
    display: flex;
    flex-direction: column;
}

main {
    display: flex;
    flex-direction: row;

    margin-bottom: 50px;
}

input[readOnly] {
    cursor: pointer;
}


/* =========================================
      App
-------------------------------------- */

.App {
    padding: 10px 20px 20px;
}

/* =========================================
      Logo
-------------------------------------- */

.Logo {
    position: fixed;

    left: 15px;
    bottom: 15px;

    width: 48px;
    height: 48px;

    zIndex: 1;

    pointer-events: none;
}


/* =========================================
      DemoAlertOverlay
-------------------------------------- */

.DemoAlertOverlay {
    position: fixed;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    padding: 100px;

    display: flex;

    flex-direction: column;

    /* justify-content: flex-start; */
    justify-content: center;
    align-items: center;

    overflow: hidden;

    z-index: 2147483647;

    letter-spacing: 2px;

    background: rgba(0, 0, 0, 0.97);
    color: rgba(255, 255, 255, 1.0);

    pointer-events: all !important;
    cursor: default !important;

    text-align: center;
}

.DemoAlertOverlay-header {

}

.DemoAlertOverlay-content {

}

.DemoAlertOverlay-logo {
    color: rgba(255, 255, 255, 1.0);

    position: absolute;

    top: 0;
    left: 0;
    right: 0;

    margin: 20px;

    font-size: 12px;
    font-weight: bold;

    text-transform: uppercase;

    text-align: center;
}

.DemoAlertOverlay-title {
    font-size: 28px;
    font-weight: 400;

    text-transform: uppercase;

    margin-bottom: 30px;

    padding-bottom: 5px;

    border-bottom: 5px solid rgba(0, 0, 0, 1.0);
}

.DemoAlertOverlay-title > p {
    margin: 0;
    padding: 0;
}

.DemoAlertOverlay-message {
    font-size: 20px;
    font-weight: 300;
}

.DemoAlertOverlay-message > p {
    /* margin: 0; */
    /* padding: 0; */
}

.DemoAlertOverlay[data-type="info"] {
    background-color: rgba(0, 0, 255, 0.98);
    color: rgba(255, 255, 255, 1.0);
}

.DemoAlertOverlay[data-type="info"] .DemoAlertOverlay-title {
    border-color: rgba(255, 255, 255, 1.0);
}

.DemoAlertOverlay[data-type="warning"] {
    background-color: rgba(255, 255, 0, 0.98);
    color: rgba(0, 0, 0, 1.0);
}

.DemoAlertOverlay[data-type="warning"] .DemoAlertOverlay-logo {
    color: rgba(0, 0, 0, 1.0);
    font-weight: 400; /* black-on-yellow issue */
}

.DemoAlertOverlay[data-type="warning"] .DemoAlertOverlay-title {
    border-color: rgba(0, 0, 0, 1.0);
}

.DemoAlertOverlay[data-type="warning"] .DemoAlertOverlay-message {
    font-weight: 400; /* black-on-yellow issue */
}

.DemoAlertOverlay[data-type="error"] {
    background-color: rgba(220, 0, 0, 0.98);
    color: rgba(255, 255, 255, 1.0);
}

.DemoAlertOverlay[data-type="error"] .DemoAlertOverlay-title {
    border-color: rgba(255, 255, 255, 1.0);
}


/* =========================================
      VideoContainer
-------------------------------------- */

.VideoContainer {
    display: inline-block;

    margin-right: 30px;

    width: 400px;
    max-width: 100%;
}


/* =========================================
      LensVideo
-------------------------------------- */

.VideoContainer .LensVideo {
    display: inline-block;
    margin-bottom: 20px;
    position: relative;
    vertical-align: top;
    flex: 0;
    user-select: none;
}

.VideoContainer .LensVideo video,
.VideoContainer .LensVideo iframe {
    display: block;

    width: 400px;

    background-color: black;
}

.VideoContainer .LensVideo-content {
    position: relative;
    overflow: hidden;

    width: auto;

    border: 2px solid black;

    border-radius: 1px;

    z-index: 1;
}

.VideoContainer .LensVideo-footer {
    margin-top: 10px;
    /* margin-left: 10px; */

    font-family: monospace;
    font-size: 12px;
}

.VideoContainer .LensVideo-meta {

}

.VideoContainer .LensVideo-meta > * {
    position: absolute;
    padding: 5px 10px;
    z-index: 1000000;
    opacity: 1;
    border-radius: 1px;
    pointer-events: none;
    font-size: 10px;
    background-color: rgba(0, 0, 0, 1);
    color: rgba(255, 255, 255, 1);
    letter-spacing: 0.5px;
}

.VideoContainer .LensVideo:hover .LensVideo-meta > * {
    display: block;
    opacity: 1;
}

.VideoContainer .LensVideo-meta-label {
    top: 0;
    left: 0;

    padding-top: 6px;
    padding-bottom: 6px;

    border-bottom-right-radius: 2px;
}

.VideoContainer .LensVideo-meta-time {
    top: 0;
    right: 0;

    padding-top: 6px;
    padding-bottom: 6px;

    border-bottom-left-radius: 2px;
}

.VideoContainer .LensVideo:hover {

}


/* =========================================
      LensVideoFrameTracking
-------------------------------------- */

.LensVideoFrameTrackingTimeline {
    display: block;

    width: 100%;

    margin-top: 20px;

    height: 40px;

    position: relative;

    cursor: not-allowed;
}

.LensVideoFrameTrackingTimeline-bar {
    display: block;

    width: 100%;

    height: 10px;
    min-width: 5px;

    background-color: rgba(0, 0, 0, 0.1);

    position: absolute;

    top: 0;

    width: 100%;
}

.LensVideoFrameTrackingTimeline-bar::after {
    display: inline-block;

    content: "";

    font-size: 10px;
    font-family: helvetica neue;

    letter-spacing: 1px;
    line-height: 1;

    position: absolute;

    top: 10px;
    left: 0;

    padding: 2px;

    color: rgba(0, 0, 0, 0.2);

    text-transform: uppercase;
}

.LensVideoFrameTrackingTimeline-bar-track {
    display: block;

    height: 100%;
    min-width: 5px;

    background-color: rgba(0, 0, 0, 0.4);

    border-left: 1px solid rgba(255, 255, 255, 1);
    border-right: 1px solid rgba(255, 255, 255, 1);

    position: relative;

    top: 0;
}

.LensVideoFrameTrackingTimeline-bar-track::after {
    display: inline-block;

    content: "track";

    font-size: 10px;
    font-family: helvetica neue;

    letter-spacing: 1px;
    line-height: 1;

    position: absolute;

    top: 10px;
    left: 0;

    padding: 2px;

    color: rgba(0, 0, 0, 0.4);

    text-transform: uppercase;
}

.LensVideoFrameTrackingTimeline-bar-seek {
    display: block;

    height: 10px;
    width: 5px;

    background-color: rgba(255, 0, 0, 1);

    outline: 1px solid rgba(255, 255, 255, 1);

    position: absolute;

    top: -0;
}

.LensVideoFrameTrackingTimeline-bar-seek::after {
    display: inline-block;

    /* content: "snapshot"; */
    content: attr(data-label);

    font-size: 10px;
    font-family: helvetica neue;

    letter-spacing: 1px;
    line-height: 1;

    position: absolute;

    top: -15px;
    left: 0;

    padding: 2px 0;

    color: rgba(255, 0, 0, 1);

    text-transform: uppercase;

    white-space: nowrap;
}

.LensVideoFrameTrackingTimeline-bar-current {
    display: inline-block;

    height: 22px;
    width: 5px;

    background-color: rgba(0, 0, 255, 1);

    outline: 1px solid rgba(255, 255, 255, 1);

    position: absolute;

    top: -12px;

    z-index: 1;
}

.LensVideoFrameTrackingTimeline-bar-current::after {
    display: inline-block;

    /* content: "current"; */
    content: attr(data-label);

    font-size: 10px;
    font-family: helvetica neue;

    letter-spacing: 1px;
    line-height: 1;

    position: absolute;

    top: -15px;
    left: 0;

    padding: 2px 0;

    color: rgba(0, 0, 255, 1);

    text-transform: uppercase;

    white-space: nowrap;
}


/* =========================================
      LensVideoFrameTicker
-------------------------------------- */

.LensVideoFrameTicker {
    user-select: none;

    margin-bottom: 20px;
}


.LensVideoFrameTicker-header {

}

.LensVideoFrameTicker-content {

}

.LensVideoFrameTicker-footer {
    font-size: 12px;
    color: rgba(0, 0, 0, 1);
}

.LensVideoFrameTicker-footer-summary-value {
    font-weight: bold;
    color: rgba(0, 0, 0, 1);
}

.LensVideoFrameTicker-footer-summary-unit {
    font-weight: normal;
    color: rgba(0, 0, 0, 1);
}

.LensVideoFrameTicker-footer-summary-divider {
    padding: 0 2px;
}

.LensVideoFrameTicker-frames {
    display: flex;
    flex-wrap: wrap;

    background: rgba(255, 255, 255, 1);

    margin-bottom: 15px;
}

.LensVideoFrameTicker-frame {
    flex: 0;

    display: inline-block;

    padding-right: 1px;
    margin-bottom: 5px;

    cursor: pointer;
}

.LensVideoFrameTicker-frame-tick {
    height: 15px;
    min-width: 5px;

    background: green;
}

.LensVideoFrameTicker-frame:last-child {
    margin-right: 0;
}

.LensVideoFrameTicker-frame:hover .LensVideoFrameTicker-frame-tick {
    outline: 2px solid black;
    z-index: 1;
    opacity: 0.999; /* HACK: z-index have no effecto */
}

.LensVideoFrameTicker-frame.current .LensVideoFrameTicker-frame-tick {
    outline: 2px solid blue;
    z-index: 1;
    opacity: 0.999; /* HACK: z-index have no effecto */
}

.LensVideoFrameTicker-frame[data-detections="0"] .LensVideoFrameTicker-frame-tick {
    background: red;
}

.LensVideoFrameTicker-frame[data-detections="0"]:hover .LensVideoFrameTicker-frame-tick {

}

.LensVideoFrameTicker-frame-tracks {
    flex: 0;

    margin-top: 1px;

    display: flex;

    flex-direction: column;

    align-items: center;
    justify-content: center;
}

.LensVideoFrameTicker-frame-track {
    width: 100%;

    margin-bottom: 1px;
}

.LensVideoFrameTicker-frame-track-tick {
    width: 100%;
    height: 3px;
}


/* =========================================
      LensVideoOverlayFrameImage
-------------------------------------- */

.LensVideoOverlayFrameImage {
    position: absolute;

    top: 0;
    left: 0;

    width: 100%;
}


/* =========================================
      DebugContainer
-------------------------------------- */

.DebugContainer {
    position: fixed;
    top: 270px;
    right: 0;
    padding: 0;
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.1);;
    margin: 1px;
    z-index: 1000000000;
    opacity: 0.95;
    cursor: default !important;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    max-width: 50vw;
    max-height: 70vh;

    display: flex;
}

.DebugContainer-content {
    flex: 1;
    overflow: scroll;
    padding: 20px;
}


/* =========================================
      SetContainer
-------------------------------------- */

.SetContainer {
    position: fixed;
    display: flex;

    justify-content: center;

    top: 0;
    left: 0;
    right: 0;

    width: 100vw;
}

.SetContainer-label {
    padding: 5px 10px 6px;

    background: purple;
    color: white;

    text-transform: uppercase;

    font-size: 10px;
    line-height: 1;
}

.SetContainer-label-prefix {
    font-weight: bold;
}

/* =========================================
      InputContainer
-------------------------------------- */

.InputContainer {
     margin-bottom: 8px;
}


/* =========================================
      InputContainer
-------------------------------------- */

.DemoInputField {
    display: flex;
    flex-direction: row;
    position: relative;
}

.DemoInputField.hidden {
    display: none;
}

.DemoInputField.disabled {
    pointer-events: none;
    opacity: 0.2;
    user-select: none;
    cursor: pointer;
}

.DemoInputField-url-input {
/*    position: relative;*/
    display: inline-block;
    width: 1000%;
/*    border: 1px solid blue;*/
}

.DemoInputField .DemoInputField-label {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    height: 30px;
    font-size: 16px;

    color: rgba(0, 0, 0, 1.00);
    align-items: center;

    z-index: 1;
}

.DemoInputField:focus-within .DemoInputField-label {
    color: rgba(0, 0, 255, 1.00);
}

.DemoInputField:focus-within .DemoInputField-url-action {
    color: rgba(255, 255, 255, 1.00);
    border-color: rgba(0, 0, 255, 1.00);
    background-color: rgba(0, 0, 255, 1.00);
}

.DemoInputField .DemoInputField-url {
    outline: none;
    border: none;

    display: block;

    height: 30px;
    width: 100%;

    padding-left: 25px;

    font-size: 12px;
    font-weight: 400;

    /* border-top: 1px solid rgba(0, 0, 0, 0.04); */
    border-bottom: 2px solid rgba(0, 0, 0, 1.00);

}

.DemoInputField .DemoInputField-url:focus {
    border-bottom-color: rgba(0, 0, 255, 1.00);
    color: rgba(0, 0, 255, 1.00);
    caret-color: rgba(255, 0, 0, 1.00);
}

.DemoInputField .DemoInputField-url::selection {
    background: rgba(255, 255, 0, 0.7);
}

.DemoInputField .DemoInputField-url::-moz-selection {
    background: rgba(255, 255, 0, 0.7);
}

.DemoInputField .DemoInputField-url.error {
    border-color: red;
}


/* =========================================
      uploadWidget
-------------------------------------- */

/* .cloudinary-button {
    height:10%;
    width: 10%;
    margin-bottom:1%;
    margin-top:.5%;
} */

/* =========================================
      DemoInputFieldSampleVideos
-------------------------------------- */

.DemoInputFieldSampleVideos {
    position: fixed;

    top: 10%;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%, 0);
/*
    right: 0;
    left: 0;
*/
    max-height: calc(100vh - 60px);
    width: 90%;

    z-index: 10000000;

/*    margin-top: 0px;*/
    padding: 25px 25px 15px;

    background: rgba(0, 0, 0, 1.00);
    color: rgba(255, 255, 255, 1.00);
    opacity: .95;

    font-size: 16px;
    font-weight: 200;

    box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);

    overflow: hidden;
    overflow-y: scroll;
}

.DemoInputFieldSampleVideos.hidden {
    display: block !important;
    height: 0;
    padding: 0;
}

.DemoInputFieldSampleVideos-header {
    position: relative;

    z-index: 1;
}

.DemoInputFieldSampleVideos-content {
    display: flex;

    flex-direction: row;
    flex-wrap: wrap;

    justify-content: space-evenly;

    pointer-events: none;
}

.DemoInputFieldSampleVideos-action-close {
    position: absolute;

    top: 0;
    right: 0;

    color: rgba(255, 255, 255, 1.00);
    text-shadow: 0 0 10px rgba(0, 0, 0, 1.00);

    font-size: 70px;
    font-weight: 200;

    line-height: 14px;

    cursor: pointer;

    display: flex;

    align-items: center;
    justify-content: center;
}

.DemoInputFieldSampleVideos-action-close:hover {
    color: blue;
}


.DemoInputField-url-sample {
    position: relative;

    margin-right: 20px;
    margin-bottom: 20px;

    cursor: pointer;

    opacity: 0.7;

    border: 1px solid purple;

    pointer-events: all;
}

.DemoInputField-url-sample:last-child {
    margin-right: 0;
}

.DemoInputField-url-sample-meta {
    position: absolute;

    display: inline-block;

    top: 0;
    left: 0;

    background: purple;
    color: white;

    font-size: 11px;
    font-weight: 300;
    line-height: 1;
    letter-spacing: 1px;

    margin: 0px;
    padding: 5px;

    border-radius: 1px;
}

.DemoInputField-url-sample.youtube {
    border-color: red;
}

.DemoInputField-url-sample.youtube .DemoInputField-url-sample-meta {
    background: red;
}

.DemoInputField-url-sample-meta-kind {

}

.DemoInputField-url-sample-video {
    display: block;

    border-radius: 1px;

    height: 120px;
}

.DemoInputField-url-sample:hover {
    opacity: 1;
}

.DemoInputField-url-actions {
    display: flex;
}

.DemoInputField .DemoInputField-url-action {
    display: inline-block;
    padding: 5px 15px;
    height: none;
    outline: none;
    box-shadow: none;
    border-radius: 100px;
    appearance: none;
    text-transform: uppercase;
    font-size: 12px;
    white-space: nowrap;

    margin-left: 5px;

    background-color: rgba(0, 0, 0, 1.00);
    color: rgba(255, 255, 255, 1.00);
    border: 2px solid rgba(0, 0, 0, 1.00);
}

.DemoInputField .DemoInputField-url-action:hover {
    opacity: 0.85;
    cursor: pointer;
}


/* =========================================
      LensVideoOverlay
-------------------------------------- */

.LensVideoOverlay {
    position: absolute;

    left: 0;
    top: 0;

    width: 100%;
    height: 100%;

    z-index: 1000000;

    pointer-events: none;
}

.LensVideoOverlay.loading {
    background: rgba(0, 0, 0, 0.5);
}


/* =========================================
      LensVideoOverlayCategories
-------------------------------------- */

.LensVideoOverlayCategories {
    position: absolute;
    left: 0;
    top: 0;
    margin: 40px 15px;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    z-index: 100000000;
    pointer-events: none;
}

.LensVideoOverlayCategories-category {
    flex: 0;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    background: rgba(0, 0, 0, 1);
    border-radius: 20px;
    padding: 10px 12px;
    line-height: 0;
    margin-bottom: 8px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    pointer-events: all;
}

.LensVideoOverlayCategories-category:hover {
    background: rgba(0, 0, 255, 1);
}

.LensVideoOverlayCategories-category:hover * {
    color: white;
}

.LensVideoOverlayCategories-category-name {
    display: block;
    color: black;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 11px;
    letter-spacing: 0.5px;
    color: rgba(255, 255, 255, 1);
}

.LensVideoOverlayCategories-category-bullet {
    display: block;
    background: black;
    border-radius: 10px;
    width: 8px;
    height: 8px;
    margin-left: 5px;
}


/* =========================================
      LensVideoOverlay
-------------------------------------- */

.LensVideoOverlayBBoxes {
    position: absolute;

    left: 0;
    top: 0;

    width: 100%;
    height: 100%;

    z-index: 10000000;

    pointer-events: none;

    /* transition: all 50ms linear; */
}

.LensVideoOverlayBBox {
    position: absolute;
    border: 3px solid black;
    background-color: rgba(255,255,255,0.1);
    border-radius: 2px;

    pointer-events: all;
}

.LensVideoOverlayBBox-label {
    background-color: black;
    color: white;
    padding: 4px 5px;
    line-height: normal;
    position: absolute;
    display: inline-block;
    font-size: 10px;
    display: none;
    border-radius: 1px;
    font-size: 10px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.LensVideoOverlayBBox-tooltip {
    display: none;
}

.LensVideoOverlayBBox:hover {
    background-color: rgba(255,255,255,0.3)
}

.LensVideoOverlayBBox:hover .LensVideoOverlayBBox-label {
    display: block;
}

.LensVideoOverlayBBox:hover .LensVideoOverlayBBox-tooltip {
    display: block;
}

.LensVideoOverlayBBox-id {
    left: -1px;
    top: -1px;
}

.LensVideoOverlayBBox-score {
    right: -1px;
    top: -1px;
}

.LensVideoOverlayBBox-class {
    left: -1px;
    bottom: -1px;
}

.LensVideoOverlayBBox-attributes {
    position: absolute;
    top: 30px;
    left: 15px;
    padding: 10px;
}


.LensVideoOverlayBBox-attribute {
    padding: 2px;
    white-space: nowrap;
}


/* =========================================
      ResultsContainer
-------------------------------------- */

.ResultsContainer {
    /* NOTE: hardcode to video-player-width + paddings, because scrolling */
    width: calc(100vw - 490px);
}


/* =========================================
      StatusContainer
-------------------------------------- */

.LoadingContainer {
    padding: 10px;
    background: rgba(0, 0, 0, 1);

    border-radius: 1px;

    display: flex;

    align-items: center;
    justify-content: center;
}

.LoadingContainer-text {
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 0.5px;
    line-height: 1;

    color: rgba(255, 255, 255, 1);
}


/* =========================================
      DemoTopMatches
-------------------------------------- */

.DemoTopMatches {
    display: block;

    margin: 0 0 20px;
    padding: 0;

    display: flex;
    flex-direction: column;

    /* NOTE: hardcode to video-player-width + paddings, because scrolling */
    width: calc(100vw - 490px);
}

.DemoTopMatches .DemoTopMatches-header {

}

.DemoTopMatches .DemoTopMatches-header-title {
    display: inline-block;

    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;

    margin: 0 0 20px;
    padding-right: 20px;

    text-transform: uppercase;

    color: rgba(0, 0, 0, 1);

    border-bottom: 2px solid rgba(0, 0, 0, 1);
    padding-bottom: 5px;
}

.DemoTopMatches-content {
    display: flex;

    flex-direction: row;

    overflow-x: scroll;
    overflow-y: hidden;

    white-space: nowrap;

    border: 1px solid #eee;

    border-radius: 1px;
}

.DemoTopMatches-matches {
    flex: 1;

    padding: 10px;

    display: flex;

    flex-direction: row;
}

.DemoTopMatches-match {

    position: relative;
    display: inline-block;

    overflow: hidden;

    display: flex;
    flex-direction: column;
    justify-content: space-around;

    margin-right: 10px;

    height: 100px;

    border: 2px solid black;
    border-radius: 1px;

    cursor: pointer;
}

.DemoTopMatches-match:hover {
    opacity: 0.9;
}

.DemoTopMatches-match:last-child {
    margin-right: 0;
}

.DemoTopMatches-match-content {
    display: flex;

    flex-direction: row;

    height: 100%;
}

.DemoTopMatches-match-image {
    flex: 0;

    height: 100%;
    width: auto;

    outline: 0;

    border: 3px solid transparent;
}

.DemoTopMatches-match-preview-actions {
    position: absolute;

    top: 0;
    left: 0;
    right: 5px;
    bottom: 5px;

    display: flex;

    flex-direction: row;

    align-items: center;
    justify-content: center;

    user-select: none;

    opacity: 0;
}

.DemoTopMatches-match-preview-action {
    display: flex;

    align-items: center;
    justify-content: center;

    background: black;
    color: white;

    font-size: 13px;
    font-weight: bold;
    line-height: 1;

    width: 25px;
    height: 25px;

    margin: 2px;

    text-align: center;

    border-radius: 50px;

    opacity: 0.8;
}

.DemoTopMatches-match-preview-action:hover {
    opacity: 1;
}

.DemoTopMatches-match:hover .DemoTopMatches-match-preview-actions {
    opacity: 1;
}

.DemoTopMatches-match-label {
    flex: 0;
    background-color: black;
    color: white;
    padding: 4px 5px;
    line-height: normal;
    display: inline-block;
    font-size: 10px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    position: absolute;

    white-space: nowrap;
    border-radius: 1px;
}

.DemoTopMatches-match-class {
    left: 0;
    bottom: 0;
    right: 0;

    /* position: static; */

    padding-right: 15px;
}


.DemoTopMatches-match-occurances {
    left: 5px;
    top: 5px;

    width: 20px;
    height: 20px;

    /* font- */
    font-size: 10px;

    border-radius: 100px;

    display: flex;

    flex-direction: center;
    justify-content: center;
}

.DemoTopMatches-match:hover {

}

.DemoTopMatches-match-link {
    top: 3px;
    right: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: black;
    color: white;
    padding: 5px;
    opacity: 0;
    border-radius: 50px;
    border: 2px solid white;
    z-index: 1;
    cursor: pointer;
}

.DemoTopMatches-match-link:hover {
    background-color: blue !important;
    border-color: blue;
}

.DemoTopMatches-match-link > svg {
    width: 12px;
    height: 12px;
}


.DemoTopMatches-match:hover .DemoTopMatches-match-image {
    /* border-color: black; */
    /* border-width: 2px; */
    /* border-style: solid; */
}

.DemoTopMatches-match:hover .DemoTopMatches-match-link {
    opacity: 1;
}

/* =========================================
      DemoAttributesModal
-------------------------------------- */

.AttributesDiv {
    z-index: 2147483648 !important;
    position: absolute;
/*    float: right;*/
    top: 10%;
    right: 2%;
    width: 300px;
    height: auto;

/*    border: 1px solid black;*/
    background: rgba(255, 255, 255, 0.9);
/*    background: rgba(0, 0, 0, 0.2);*/
    border-radius: 4px !important;
    padding: 20px;

    box-shadow: 5px 6px 10px 4px #888888;
 }



/* =========================================
      DemoFrameSnapshotsPreviewer
-------------------------------------- */

.DemoFrameSnapshotsPreviewer {
    position: fixed;

    left: 0;
    bottom: 0;

    z-index: 1;

    width: 100vw;
}

.DemoFrameSnapshotsPreviewer-header {

}

.DemoFrameSnapshotsPreviewer-content {

}

.DemoFrameSnapshotsPreviewer-snapshots {
    display: flex;

    /* flex-wrap: wrap; */
    flex-direction: row;

    background: rgba(0, 0, 0, 1.0);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);

    padding: 0;

    overflow: hidden;
    overflow-x: scroll;
}

.DemoFrameSnapshotsPreviewer-snapshot {
    display: inline-block;

    margin-right: 1px;
    margin-bottom: 1px;

    border-radius: 1px;

    position: relative;

    flex: 0;

    height: 40vh;
}

.DemoFrameSnapshotsPreviewer-snapshot-bboxes {
    position: absolute;

    top: 0;
    left: 0;

    right: 0;
    bottom: 0;

    width: 100%;
    height: 100%;

    z-index: 1;
}

.DemoFrameSnapshotsPreviewer-snapshot-bbox {
    position: absolute;

    top: 0;
    left: 0;

    min-width: 5px;
    min-width: 5px;

    outline: 1px solid white;
    border: 3px solid black;

    border-radius: 1px;
}

.DemoFrameSnapshotsPreviewer-snapshot-label {
    background: rgba(0, 0, 0, 0.5);
    color: rgba(255, 255, 255, 1.0);

    font-size: 11px;
    font-weight: 300;
    letter-spacing: 0.5px;

    text-transform: uppercase;

    white-space: nowrap;

    position: absolute;

    left: 0;

    padding: 4px 8px;

    border-radius: 1px;

    z-index: 1;
}

.DemoFrameSnapshotsPreviewer-snapshot-id {
    top: 0;
}

.DemoFrameSnapshotsPreviewer-snapshot-timestamp {
    bottom: 0;
    right: 0;
}

.DemoFrameSnapshotsPreviewer-snapshot:last-child {
    margin-right: 0;
}

.DemoFrameSnapshotsPreviewer-snapshot-image {
    display: block;

    width: auto;
    height: 40vh;

    border: 0;
    outline: 0;
}

/* =========================================
      DemoResults
-------------------------------------- */

.DemoResults {
    /* NOTE: hardcode to video-player-width + paddings, because scrolling */
    width: calc(100vw - 490px);

    padding-left: 0;
    padding-right: 0;
}

.DemoResults-header {

}

.DemoResults-header-title {
    display: inline-block;

    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1px;

    margin: 20px 0 20px;
    padding-right: 20px;

    text-transform: uppercase;

    color: rgba(0, 0, 0, 1);

    border-bottom: 2px solid black;
    padding-bottom: 5px;
}

.DemoResults-content {
    display: flex;
    flex-direction: row;
}

.DemoResults-empty {
    text-transform: uppercase;
    font-size: 11px;
    color: rgba(0, 0, 0, 0.2);

    white-space: nowrap;

    padding: 10px;
}

.DemoResults-results {
    width: 100%;
}

.DemoResults-result {
    margin-bottom: 15px;
    position: relative;
    max-width: 100%;
    user-select: none;
    cursor: default;
    transition: all 0.1s ease-in;

    border-radius: 1px;
    border: 2px solid rgba(0, 0, 0, 1.00);
}

.DemoResults-result.boundingbox.active {
}

.DemoResults-result.boundingbox.inactive {
    display: none;
}

.DemoResults-result.result.active {
    /* display: block; */
}

.DemoResults-result.result.inactive {
    opacity: 0.2;
}

.DemoResults-result-label {
    background-color: black;
    color: white;
    padding: 4px 5px;
    line-height: normal;
    position: absolute;
    display: inline-block;
    font-size: 10px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.5px;

    border-radius: 1px;
}

.DemoResults-result-id {
    left: -1px;
    top: -1px;
}

.DemoResults-result-score {
    right: -1px;
    top: -1px;
}

.DemoResults-result-class {
    left: -1px;
    bottom: -1px;
}

.DemoResults-result-accuracy {
    right: -1px;
    bottom: -1px;
}

.DemoResults-result-matches {
    user-select: none;
}

.DemoResults-result-matches-scroll {
    flex: 0;
    display: flex;
    flex-direction: row;
    height: 100px;
    padding: 2px;
    border-radius: 2px;
    overflow: scroll !important;
    width: 100%;

    overflow-scrolling: touch;
}

.DemoResults-result-match {
    height: 100%;
    margin-right: 10px;
    display: block;
    cursor: default !important;
    position: relative;
    cursor: pointer !important;
}

.DemoResults-result-match:hover {
    opacity: 0.9;
}

.DemoResults-result-match:hover .DemoResults-result-match-link {
    opacity: 1 !important;
}

.DemoResults-result-match.rejected {
    opacity: 0.3;
}

.DemoResults-result-match-image {
    display: block;
    height: 100%;
    background-color: rgba(0,0,0,0.05);
    border: 2px solid white;
}

.DemoResults-result-match-label {
    background-color: black;
    color: white;
    padding: 4px 5px;
    line-height: normal;
    position: absolute;
    display: inline-block;
    font-size: 10px;
    border-radius: 2px;
    display: none;

    align-items: center;
    justify-content: center;
}

.DemoResults-result-match-score {
    right: -1px;
    top: 25px;
    border-radius: 15px;
    padding: 2px 6px 3px;
    line-height: 1;
    border: 0px solid white;
}

.DemoResults-result-match-link {
    right: 0;
    bottom: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: black;
    color: white;
    padding: 5px;
    opacity: 0;
    border-radius: 50px;
    border: 2px solid white;
    z-index: 1;
    cursor: pointer;
}

.DemoResults-result-match-link:hover {
    background-color: blue !important;
    border-color: blue;
}

.DemoResults-result-match-link > svg {
    width: 12px;
    height: 12px;
}


.DemoResults-result:hover .DemoResults-result-match-label {
    display: flex;
}

.DemoResults-result-match:hover .DemoResults-result-match-image {
    /* border-color: black; */
    /* border-width: 2px; */
    /* border-style: solid; */
}


/* =========================================
      LensVideoControls
-------------------------------------- */

.LensVideoControls {
    display: flex;

    position: absolute;
    z-index: 10000000000;
    right: 0;
    bottom: 0;
    cursor: pointer;

    margin: 20px 25px;

    pointer-events: none;
}

.LensVideoControlGroup {

}

.LensVideoControlGroup:last-child {
    border-right: none;
}

.LensVideoControl {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    border: 0;
    outline: 0;
    padding: 0;
    margin: 0;

    flex: 0;

    /* margin-right: 15px; */

    cursor: pointer;

    background: none;

    pointer-events: all;

    border: 2px solid rgba(255, 255, 255, 1);
    background: rgba(255, 255, 255, 1);

    border-radius: 100px;
    padding: 20px;

    display: inline-flex;
    flex-direction: center;
    align-items: center;

    box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
}

.LensVideoControl:last-child {
    margin-right: 0;
}

.LensVideoControl svg {
    fill: rgba(0, 0, 0, 1);

    font-size: 32px;
}

.LensVideoControl:hover {
    background: rgba(0, 0, 255, 1);
    border-color: rgba(0, 0, 255, 1);

}

.LensVideoControl:hover svg {
    fill: rgba(255, 255, 255, 1);
    /* fill: rgba(0, 0, 255, 1); */
}

.LensVideoControl[hidden] {
    display: none;
}

.LensVideoControl[disabled] {
    opacity: 0.15 !important;
    pointer-events: none;
}


/* =========================================
      DemoServerProcess*
-------------------------------------- */

.DemoServerProcessManager {
    display: block;

    /* outline: 1px solid green; */
}

.DemoServerProcessManager.minimized {
    display: flex;

    flex-direction: row;

    align-items: flex-start;
    justify-content: flex-start;

    margin: 0 0 15px;

    font-size: 10px;
    font-weight: 400;
}

.DemoServerProcessManager.minimized .DemoServerProcessQueue {
    display: inline-flex;

    flex-direction: row;

    align-items: center;
    justify-content: flex-start;

    margin-right: 25px;

    text-transform: uppercase;
}

.DemoServerProcessManager.minimized .DemoServerProcessQueue[data-queue-jobs-count="0"] {
    display: none;
}

.DemoServerProcessManager.minimized .DemoServerProcessQueue:last-child {
    margin-right: 0;
}

.DemoServerProcessManager.minimized .DemoServerProcessQueue-header {
    margin-right: 7px;

    font-weight: bold;
}

.DemoServerProcessManager.minimized .DemoServerProcessQueue-content {
    display: flex;

    flex-direction: row;

    align-items: flex-start;
    justify-content: flex-start;
}

.DemoServerProcessManager.minimized .DemoServerProcessJob {
    display: inline-flex;

    flex-direction: row;

    align-items: flex-start;
    justify-content: flex-start;

    margin-right: 3px;

    width: 8px;
    height: 8px;

    border-radius: 100px;

    background: rgba(0, 0, 0, 1.0);
    border: 0px solid black;

    /* outline: 1px solid red; */
}

.DemoServerProcessManager.minimized .DemoServerProcessJob:last-child {
    margin-right: 0;
}

.DemoServerProcessManager.minimized .DemoServerProcessJob-header {
    margin-right: 5px;
}

.DemoServerProcessManager.minimized .DemoServerProcessJob-content {
    display: flex;

    flex-direction: row;

    align-items: flex-start;
    justify-content: flex-start;
}

.DemoServerProcessManager.minimized .DemoServerProcessJob > * {
    display: none;
}

.DemoServerProcessManager.minimized .DemoServerProcessJob[data-status="pending"] {
    background-color: rgba(200, 200, 200, 1.0);
}

.DemoServerProcessManager.minimized .DemoServerProcessJob[data-status="active"] {
    background-color: rgba(0, 0, 255, 1.0);
    border-color: rgba(0, 0, 0, 1.0);
}

.DemoServerProcessManager.minimized .DemoServerProcessJob[data-status="failed"] {
    background-color: rgba(200, 0, 0, 1.0);
}

.DemoServerProcessManager.minimized .DemoServerProcessJob[data-status="successful"] {
    background-color: rgba(0, 200, 0, 1.0);
}

.DemoServerProcessManager.minimized .DemoServerProcessJob[data-status="unknown"] {
    background-color: rgba(0, 0, 0, 1.0);
}


/* =========================================
      DemoVideoStatus
-------------------------------------- */

.DemoVideoStatus {
    display: block;

    width: 100%;
}

.DemoVideoStatus-stats {
    display: block;

    margin-bottom: 15px;

    user-select: none;
}

.DemoVideoStatus-stats-times {
    display: flex;

    width: 100%;

    cursor: pointer !important;
}

.DemoVideoStatus-stats-times:hover .DemoVideoStatus-stats-time {
   width: auto !important;
}

.DemoVideoStatus-stats-time {
    display: inline-flex;

    align-items: center;
    justify-content: flex-start;

    flex: 1 0 auto;
    /* flex-grow: 1 0 auto; */

    height: 100%;

    padding: 6px 10px;
    margin-right: 2px;

    overflow: hidden;

    user-select: none;

    font-size: 10px;

    border-radius: 0px;

    background-color: black;
    color: white;
}

.DemoVideoStatus-stats-time.pending {
    animation: 1s ;
}

.DemoVideoStatus-stats-time:hover {
    opacity: 0.9;
}

.DemoVideoStatus-stats-time:first-child {
    border-top-left-radius: 1px;
    border-bottom-left-radius: 1px;
}

.DemoVideoStatus-stats-time:last-child {
    border-top-right-radius: 1px;
    border-bottom-right-radius: 1px;

    margin-right: 0;
}

.DemoVideoStatus-stats-time-text {
    white-space: nowrap;
}

.DemoVideoStatus-stats-labels {
    display: flex;

    flex-direction: row;
    flex-wrap: wrap;

    justify-content: center;

    width: 95%;

    overflow: scroll;

    margin-top: 10px;
    margin-bottom: 10px;
}

.DemoVideoStatus-stats-labels::-webkit-scrollbar {
    width: 0 !important;
}

.DemoVideoStatus-stats-label {
    display: flex;

    flex-direction: row;

    align-items: center;

    margin-bottom: 7px;
}

.DemoVideoStatus-stats-label-box {
    display: inline-block;

    width: 16px;
    height: 16px;

    margin-right: 5px;

    border-radius: 15px;

    background-color: black;
}

.DemoVideoStatus-stats-label-text {
    margin-right: 15px;

    text-transform: uppercase;

    font-size: 10px;
    letter-spacing: 0.5px;
}

.DemoVideoStatus-stats-time-text.procent {
    height: 100%;
}


/* =========================================
      OptionsContainer
-------------------------------------- */

.OptionsContainer {
    display: flex;

    flex-wrap: wrap;

    flex-direction: row;

    margin-top: 5px;
    margin-bottom: 10px;
}


/* =========================================
      DemoStatus
-------------------------------------- */

.DemoStatus {
/*
    user-select: none;
    position: absolute;
    top: 5px;
    left: 40%;
    z-index: 2147483647;
*/

/*    border: 1px solid red;*/
}


/* =========================================
      DemoServiceActions
-------------------------------------- */

.DemoServiceActions {
    display: flex;

    flex-direction: row;

    margin-left: -5px;
    margin-bottom: 10px;

    padding: 2px 0;

    align-items: flex-start;
    justify-content: flex-start;
}

.DemoServiceActions-label {
    font-size: 10px;
    line-height: 1;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    padding: 5px 10px;

    user-select: none;

    /* color: rgba(0, 0, 0, 0.2); */

    font-weight: bold;
}

.DemoServiceActions-action {
    display: block;

    margin-right: 6px;

    background-color: black;
    color: white;

    font-size: 10px;
    line-height: 1;
    letter-spacing: 0.5px;

    padding: 5px 10px;

    border-radius: 50px;

    text-transform: uppercase;

    /* opacity: 0.2; */

    cursor: pointer;

    user-select: none;
}

.DemoServiceActions-action:hover {
    opacity: 1;
}


/* =========================================
      LensVideoFrameTracking
-------------------------------------- */

.LensVideoFrameTracking {
    display: flex;

    flex-direction: row-reverse;

    align-items: flex-start;
}

.LensVideoFrameTrackingActions {
    display: flex;

    flex-direction: row;

    padding: 2px 0;

    align-items: flex-start;
    justify-content: flex-start;

    margin-top: 10px;
    margin-left: 10px;
}

.LensVideoFrameTrackingActions-label {
    font-size: 10px;
    line-height: 1;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    padding: 5px 10px;

    user-select: none;

    /* color: rgba(0, 0, 0, 0.2); */

    font-weight: bold;
}

.LensVideoFrameTrackingActions-action {
    display: block;

    margin-right: 6px;

    background-color: red;
    color: white;

    font-size: 10px;
    line-height: 1;
    letter-spacing: 0.5px;
    font-weight: bold;

    padding: 5px 10px;

    border-radius: 50px;

    text-transform: uppercase;

    /* opacity: 0.2; */

    cursor: pointer;

    user-select: none;

    box-shadow: none;
    border: none;
    outline: none;

    border: 2px solid red;
}

.LensVideoFrameTrackingActions-action:hover {
    opacity: 1;

    border: 2px solid blue;
}

/* =========================================
      DemoServiceCacheModes
-------------------------------------- */

.DemoServiceCatalogSelection {
    display: flex;

    flex-direction: row;

    padding: 2px 0;

    align-items: flex-start;
    justify-content: flex-start;

    margin-bottom: 10px;
}

.DemoServiceCatalogSelection-label {
    font-size: 10px;
    line-height: 1;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    padding: 5px 10px;

    user-select: none;

    /* color: rgba(0, 0, 0, 0.2); */

    font-weight: bold;
}

.DemoServiceCatalogSelection-action {
    display: block;

    margin-right: 6px;

    background-color: black;
    color: white;

    font-size: 10px;
    line-height: 1;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    padding: 5px 10px;

    border-radius: 50px;

    opacity: 0.15;

    cursor: pointer;

    position: relative;

    user-select: none;
}

.DemoServiceCatalogSelection-action.selected,
.DemoServiceCatalogSelection-action:hover {
    opacity: 1;
}

.DemoServiceCatalogSelection-action-menu {
    min-width: 150px;
    width: auto;

    pointer-events: none;

    background: rgba(255, 255, 255, 1);
    border: 3px solid rgba(0, 0, 0, 1);

    position: fixed;

    z-index: 2147483647;

    /* padding: 10px; */

    margin-left: -5px;
    margin-top: 2px;

    border-radius: 2px;

    display: none;

    pointer-events: none;

    color: rgba(0, 0, 0, 1);

    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.DemoServiceCatalogSelection-action-menu-body {
    pointer-events: all;

    max-height: 50vh;

    overflow-x: hidden;
    overflow-y: scroll;
}

.DemoServiceCatalogSelection-action:hover .DemoServiceCatalogSelection-action-menu {
    display: block;
}

.DemoServiceCatalogSelection-select-group {
    padding: 10px 10px 0;
}

.DemoServiceCatalogSelection-select-group:last-child {
    padding-bottom: 10px;
}

.DemoServiceCatalogSelection-select-group-label {
    font-weight: bold;

    margin-bottom: 10px;
}

.DemoServiceCatalogSelection-select-group-options {
    margin-left: 15px;
}

.DemoServiceCatalogSelection-select-group-option {
    margin-bottom: 10px;

    align-items: center;

    user-select: none;

    opacity: 0.15;
}

.DemoServiceCatalogSelection-select-group-option-checkbox {
    display: inline-block;

    outline: 2px solid rgba(0, 0, 0, 1);
    border: 0px solid rgba(255, 255, 255, 1);

    padding: 3px;

    margin-right: 10px;
}

.DemoServiceCatalogSelection-select-group-option-label {
    display: inline-block;
}

.DemoServiceCatalogSelection-select-group-option:hover {
    opacity: 1;
}

.DemoServiceCatalogSelection-select-group-option.selected {
    opacity: 1;
}

.DemoServiceCatalogSelection-select-group-option.selected .DemoServiceCatalogSelection-select-group-option-checkbox {
    background: rgba(0, 0, 0, 1);
}

.DemoServiceFPS {
    display: flex;

    flex-direction: row;

    padding: 2px 0;

    align-items: flex-start;
    justify-content: flex-start;

    margin-bottom: 10px;
}

.DemoServiceFPS-label {
    font-size: 10px;
    line-height: 1;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    padding: 5px 10px;

    user-select: none;

    font-weight: bold;
}

.DemoServiceFPS-input {
    flex: 0;

    display: block;

    margin-right: 6px;

    background-color: white;
    color: black;

    font-size: 10px;
    line-height: 1;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    padding: 3px 5px 3px 10px;

    border-radius: 50px;

    cursor: pointer;

    position: relative;

    user-select: none;

    box-shadow: none;
    outline: none;
    border: none;
    appearance: none;

    border: 2px solid black;
}

.DemoServiceLimit {
    display: flex;

    flex-direction: row;

    padding: 2px 0;

    align-items: flex-start;
    justify-content: flex-start;

    margin-bottom: 10px;
}

.DemoServiceLimit-label {
    font-size: 10px;
    line-height: 1;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    padding: 5px 10px;

    user-select: none;

    font-weight: bold;
}

.DemoServiceLimit-input {
    flex: 0;

    display: block;

    margin-right: 6px;

    background-color: white;
    color: black;

    font-size: 10px;
    line-height: 1;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    padding: 3px 5px 3px 10px;

    border-radius: 50px;

    cursor: pointer;

    position: relative;

    user-select: none;

    box-shadow: none;
    outline: none;
    border: none;
    appearance: none;

    border: 2px solid black;
}

.DemoServiceRelevance {
    display: flex;

    flex-direction: row;

    padding: 2px 0;

    align-items: flex-start;
    justify-content: flex-start;

    margin-bottom: 10px;
}

.DemoServiceRelevance-label {
    font-size: 10px;
    line-height: 1;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    padding: 5px 10px;

    user-select: none;

    font-weight: bold;
}

.DemoServiceRelevance-input {
    flex: 0;

    display: block;

    margin-right: 6px;

    background-color: white;
    color: black;

    font-size: 10px;
    line-height: 1;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    padding: 3px 5px 3px 10px;

    border-radius: 50px;

    cursor: pointer;

    position: relative;

    user-select: none;

    box-shadow: none;
    outline: none;
    border: none;
    appearance: none;

    border: 2px solid black;
}


.DemoServiceOptions {
    display: flex;

    flex-direction: row;

    padding: 2px 0;

    align-items: flex-start;
    justify-content: flex-start;
}

.DemoServiceOptions-label {
    font-size: 10px;
    line-height: 1;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    padding: 5px 10px;

    user-select: none;

    font-weight: bold;
}

.DemoServiceOptions-option {
    display: inline-flex;
}

.DemoServiceOptions-input {
    appearance: none;

    display: block;

    margin-right: 6px;

    background-color: rgba(0, 0, 0, 0.2);
    color: black;

    font-size: 10px;
    line-height: 1;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    padding: 3px 5px 3px 10px;

    border-radius: 50px;

    cursor: pointer;

    position: relative;

    user-select: none;

    box-shadow: none;
    outline: none;
    border: none;
    appearance: none;

    border: 2px solid transparent;

    cursor: pointer;
}

.DemoServiceOptions-option label {
    padding: 5px 10px 5px 0;

    cursor: pointer;
}

.DemoServiceOptions-input:checked {
    background: black;
}

/* .DemoServiceOptions-input {
    display: block;

    margin-right: 6px;

    background-color: rgba(0, 0, 0, 0.2);
    color: black;

    font-size: 10px;
    line-height: 1;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    padding: 3px 5px 3px 10px;

    border-radius: 50px;

    cursor: pointer;

    position: relative;

    user-select: none;

    box-shadow: none;
    outline: none;
    border: none;
    appearance: none;

    border: 2px solid black;
} */

.DemoServiceTrack {
    display: flex;

    flex-direction: row;

    padding: 2px 0;

    align-items: flex-start;
    justify-content: flex-start;
}

.DemoServiceTrack-label {
    font-size: 10px;
    line-height: 1;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    padding: 5px 10px;

    user-select: none;

    /* color: rgba(0, 0, 0, 0.2); */

    font-weight: bold;
}

.DemoServiceTrack-sep {
    font-size: 10px;
    line-height: 1;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    padding: 5px 2px;

    user-select: none;

    /* color: rgba(0, 0, 0, 0.2); */

    font-weight: bold;
}

.DemoServiceTrack-input {
    display: block;

    background-color: white;
    color: black;

    font-size: 10px;
    line-height: 1;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    padding: 3px 5px 3px 10px;

    border-radius: 50px;

    /* opacity: 0.15; */

    cursor: pointer;

    position: relative;

    user-select: none;

    box-shadow: none;
    outline: none;
    border: none;
    appearance: none;

    border: 2px solid black;
}

.DemoServiceTrack-input-begin {
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;

    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    border-right-width: 1px;
}

.DemoServiceTrack-input-end {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;

    border-left-width: 1px;
}

/* =========================================
      Status
-------------------------------------- */

.Status {
    display: inline-flex;

    justify-content: center;
    align-items: center;

    color: #ccc;

    text-transform: uppercase;

    font-size: 9px;
    font-weight: 400;

    letter-spacing: 1px;

    margin-right: 15px;
    margin-bottom: 15px;

    border-radius: 100px;

    user-select: none;
}

.Status-label {

}

.Status-value {

}

.Status:last-child {
    margin-right: 0;
}

.Status > * {

}

.Status::after {
    padding-left: 5px;
    font-size: 7px;
}

.Status[data-status="true"] {
    /* background-color: green; */
    /* color: green; */
}

.Status[data-status="true"]::after {
    content: " ⬤";
    color: rgba(0, 200, 0, 1.0);
}

.Status[data-status="true"]::after {
    content: " ⬤";
    color: rgba(0, 200, 0, 1.0);
}

.Status[data-status="true"][data-quality="false"]::after {
    color: rgba(240, 240, 0, 1.0);
}

.Status[data-status="false"] {
    /* background-color: rgba(230, 0, 0, 1.0); */
    /* color: rgba(230, 0, 0, 1.0); */
}

.Status[data-status="false"]::after {
    content: " ⬤";
    color: rgba(230, 0, 0, 1.0);
}

.BackendStatus {

}

.InternetStatus {

}

.SSLStatus {

}


@media screen
  and (max-width: 600px)
  and (orientation: portrait) {
    body {
        /* border: 2px solid red !important; */

        margin: 15px
    }

    .App {
        padding: 0;
    }

    .DemoInputField-url-actions {
        display: none;
    }

    .DemoVideoStatus-stats-labels {
        justify-content: flex-start;
        display: none;
    }

    .VideoContainer .LensVideo {
        max-width: 100%;
    }

    .DemoTopMatches {
        width: calc(100vw - (2 * 15px));
    }

    .DemoResults {
        width: calc(100vw - (2 * 15px));
    }

    .DemoVideoStatus {
        width: calc(100vw - (2 * 15px));
    }

}

@media screen
  and (max-width: 1024px) {

    .App main {
        flex-direction: column;
    }

    .DemoTopMatches {
        width: calc(100vw - (2 * 30px));
    }

    .DemoResults {
        width: calc(100vw - (2 * 30px));
    }

    .DemoVideoStatus {
        width: calc(100vw - (2 * 30px));
    }

    .DemoVideoStatus-stats-labels {
        /* display: none; */
    }
}


/* =========================================
      COLORS
-------------------------------------- */

/* colors: text */

.text-aqua {
    color: aqua
}
.text-azure {
    color: azure
}
.text-beige {
    color: beige
}
.text-black {
    color: black
}
.text-blue {
    color: blue
}
.text-brown {
    color: brown
}
.text-cyan {
    color: cyan
}
.text-darkblue {
    color: darkblue
}
.text-darkcyan {
    color: darkcyan
}
.text-darkgrey {
    color: darkgrey
}
.text-darkgreen {
    color: darkgreen
}
.text-darkkhaki {
    color: darkkhaki
}
.text-darkmagenta {
    color: darkmagenta
}
.text-darkolivegreen {
    color: darkolivegreen
}
.text-darkorange {
    color: darkorange
}
.text-darkorchid {
    color: darkorchid
}
.text-darkred {
    color: darkred
}
.text-darksalmon {
    color: darksalmon
}
.text-darkviolet {
    color: darkviolet
}
.text-fuchsia {
    color: fuchsia
}
.text-gold {
    color: gold
}
.text-green {
    color: green
}
.text-indigo {
    color: indigo
}
.text-khaki {
    color: khaki
}
.text-lightblue {
    color: lightblue
}
.text-lightcyan {
    color: lightcyan
}
.text-lightgreen {
    color: lightgreen
}
.text-lightgrey {
    color: lightgrey
}
.text-lightpink {
    color: lightpink
}
.text-lightyellow {
    color: lightyellow
}
.text-lime {
    color: lime
}
.text-magenta {
    color: magenta
}
.text-maroon {
    color: maroon
}
.text-navy {
    color: navy
}
.text-olive {
    color: olive
}
.text-orange {
    color: orange
}
.text-pink {
    color: pink
}
.text-purple {
    color: purple
}
.text-violet {
    color: violet
}
.text-red {
    color: red
}
.text-silver {
    color: silver
}
.text-white {
    color: white
}

.text-yellow {
    color: yellow
}

/* colors: background */

.background-aqua {
    background-color: aqua
}
.background-azure {
    background-color: azure
}
.background-beige {
    background-color: beige
}
.background-black {
    background-color: black
}
.background-blue {
    background-color: blue
}
.background-brown {
    background-color: brown
}
.background-cyan {
    background-color: cyan
}
.background-darkblue {
    background-color: darkblue
}
.background-darkcyan {
    background-color: darkcyan
}
.background-darkgrey {
    background-color: darkgrey
}
.background-darkgreen {
    background-color: darkgreen
}
.background-darkkhaki {
    background-color: darkkhaki
}
.background-darkmagenta {
    background-color: darkmagenta
}
.background-darkolivegreen {
    background-color: darkolivegreen
}
.background-darkorange {
    background-color: darkorange
}
.background-darkorchid {
    background-color: darkorchid
}
.background-darkred {
    background-color: darkred
}
.background-darksalmon {
    background-color: darksalmon
}
.background-darkviolet {
    background-color: darkviolet
}
.background-fuchsia {
    background-color: fuchsia
}
.background-gold {
    background-color: gold
}
.background-green {
    background-color: green
}
.background-indigo {
    background-color: indigo
}
.background-khaki {
    background-color: khaki
}
.background-lightblue {
    background-color: lightblue
}
.background-lightcyan {
    background-color: lightcyan
}
.background-lightgreen {
    background-color: lightgreen
}
.background-lightgrey {
    background-color: lightgrey
}
.background-lightpink {
    background-color: lightpink
}
.background-lightyellow {
    background-color: lightyellow
}
.background-lime {
    background-color: lime
}
.background-magenta {
    background-color: magenta
}
.background-maroon {
    background-color: maroon
}
.background-navy {
    background-color: navy
}
.background-olive {
    background-color: olive
}
.background-orange {
    background-color: orange
}
.background-pink {
    background-color: pink
}
.background-purple {
    background-color: purple
}
.background-violet {
    background-color: violet
}
.background-red {
    background-color: red
}
.background-silver {
    background-color: silver
}
.background-white {
    background-color: white
}
.background-yellow {
    background-color: yellow
}


/* =========================================
      Color Utils
-------------------------------------- */

/* colors: border */

.border-aqua {
    border-color: aqua
}
.border-azure {
    border-color: azure
}
.border-beige {
    border-color: beige
}
.border-black {
    border-color: black
}
.border-blue {
    border-color: blue
}
.border-brown {
    border-color: brown
}
.border-cyan {
    border-color: cyan
}
.border-darkblue {
    border-color: darkblue
}
.border-darkcyan {
    border-color: darkcyan
}
.border-darkgrey {
    border-color: darkgrey
}
.border-darkgreen {
    border-color: darkgreen
}
.border-darkkhaki {
    border-color: darkkhaki
}
.border-darkmagenta {
    border-color: darkmagenta
}
.border-darkolivegreen {
    border-color: darkolivegreen
}
.border-darkorange {
    border-color: darkorange
}
.border-darkorchid {
    border-color: darkorchid
}
.border-darkred {
    border-color: darkred
}
.border-darksalmon {
    border-color: darksalmon
}
.border-darkviolet {
    border-color: darkviolet
}
.border-fuchsia {
    border-color: fuchsia
}
.border-gold {
    border-color: gold
}
.border-green {
    border-color: green
}
.border-indigo {
    border-color: indigo
}
.border-khaki {
    border-color: khaki
}
.border-lightblue {
    border-color: lightblue
}
.border-lightcyan {
    border-color: lightcyan
}
.border-lightgreen {
    border-color: lightgreen
}
.border-lightgrey {
    border-color: lightgrey
}
.border-lightpink {
    border-color: lightpink
}
.border-lightyellow {
    border-color: lightyellow
}
.border-lime {
    border-color: lime
}
.border-magenta {
    border-color: magenta
}
.border-maroon {
    border-color: maroon
}
.border-navy {
    border-color: navy
}
.border-olive {
    border-color: olive
}
.border-orange {
    border-color: orange
}
.border-pink {
    border-color: pink
}
.border-purple {
    border-color: purple
}
.border-violet {
    border-color: violet
}
.border-red {
    border-color: red
}
.border-silver {
    border-color: silver
}
.border-white {
    border-color: white
}
.border-yellow {
    border-color: yellow
}

/* colors: outline */

.outline-aqua {
    outline-color: aqua
}
.outline-azure {
    outline-color: azure
}
.outline-beige {
    outline-color: beige
}
.outline-black {
    outline-color: black
}
.outline-blue {
    outline-color: blue
}
.outline-brown {
    outline-color: brown
}
.outline-cyan {
    outline-color: cyan
}
.outline-darkblue {
    outline-color: darkblue
}
.outline-darkcyan {
    outline-color: darkcyan
}
.outline-darkgrey {
    outline-color: darkgrey
}
.outline-darkgreen {
    outline-color: darkgreen
}
.outline-darkkhaki {
    outline-color: darkkhaki
}
.outline-darkmagenta {
    outline-color: darkmagenta
}
.outline-darkolivegreen {
    outline-color: darkolivegreen
}
.outline-darkorange {
    outline-color: darkorange
}
.outline-darkorchid {
    outline-color: darkorchid
}
.outline-darkred {
    outline-color: darkred
}
.outline-darksalmon {
    outline-color: darksalmon
}
.outline-darkviolet {
    outline-color: darkviolet
}
.outline-fuchsia {
    outline-color: fuchsia
}
.outline-gold {
    outline-color: gold
}
.outline-green {
    outline-color: green
}
.outline-indigo {
    outline-color: indigo
}
.outline-khaki {
    outline-color: khaki
}
.outline-lightblue {
    outline-color: lightblue
}
.outline-lightcyan {
    outline-color: lightcyan
}
.outline-lightgreen {
    outline-color: lightgreen
}
.outline-lightgrey {
    outline-color: lightgrey
}
.outline-lightpink {
    outline-color: lightpink
}
.outline-lightyellow {
    outline-color: lightyellow
}
.outline-lime {
    outline-color: lime
}
.outline-magenta {
    outline-color: magenta
}
.outline-maroon {
    outline-color: maroon
}
.outline-navy {
    outline-color: navy
}
.outline-olive {
    outline-color: olive
}
.outline-orange {
    outline-color: orange
}
.outline-pink {
    outline-color: pink
}
.outline-purple {
    outline-color: purple
}
.outline-violet {
    outline-color: violet
}
.outline-red {
    outline-color: red
}
.outline-silver {
    outline-color: silver
}
.outline-white {
    outline-color: white
}

.outline-yellow {
    outline-color: yellow
}

/* colors: input[range] */

.input-range-aqua::-webkit-slider-runnable-track { background-color: aqua !important; }
.input-range-aqua::-moz-range-track { background-color: aqua !important; }
.input-range-aqua::-ms-track { background-color: aqua !important; }
.input-range-aqua::-webkit-slider-thumb { background-color: aqua !important; }
.input-range-aqua::-moz-range-thumb { background-color: aqua !important; }
.input-range-aqua::-ms-thumb { background-color: aqua !important; }

.input-range-azure::-webkit-slider-runnable-track { background-color: azure !important; }
.input-range-azure::-moz-range-track { background-color: azure !important; }
.input-range-azure::-ms-track { background-color: azure !important; }
.input-range-azure::-webkit-slider-thumb { background-color: azure !important; }
.input-range-azure::-moz-range-thumb { background-color: azure !important; }
.input-range-azure::-ms-thumb { background-color: azure !important; }

.input-range-beige::-webkit-slider-runnable-track { background-color: beige !important; }
.input-range-beige::-moz-range-track { background-color: beige !important; }
.input-range-beige::-ms-track { background-color: beige !important; }
.input-range-beige::-webkit-slider-thumb { background-color: beige !important; }
.input-range-beige::-moz-range-thumb { background-color: beige !important; }
.input-range-beige::-ms-thumb { background-color: beige !important; }

.input-range-black::-webkit-slider-runnable-track { background-color: black !important; }
.input-range-black::-moz-range-track { background-color: black !important; }
.input-range-black::-ms-track { background-color: black !important; }
.input-range-black::-webkit-slider-thumb { background-color: black !important; }
.input-range-black::-moz-range-thumb { background-color: black !important; }
.input-range-black::-ms-thumb { background-color: black !important; }

.input-range-blue::-webkit-slider-runnable-track { background-color: blue !important; }
.input-range-blue::-moz-range-track { background-color: blue !important; }
.input-range-blue::-ms-track { background-color: blue !important; }
.input-range-blue::-webkit-slider-thumb { background-color: blue !important; }
.input-range-blue::-moz-range-thumb { background-color: blue !important; }
.input-range-blue::-ms-thumb { background-color: blue !important; }

.input-range-brown::-webkit-slider-runnable-track { background-color: brown !important; }
.input-range-brown::-moz-range-track { background-color: brown !important; }
.input-range-brown::-ms-track { background-color: brown !important; }
.input-range-brown::-webkit-slider-thumb { background-color: brown !important; }
.input-range-brown::-moz-range-thumb { background-color: brown !important; }
.input-range-brown::-ms-thumb { background-color: brown !important; }

.input-range-cyan::-webkit-slider-runnable-track { background-color: cyan !important; }
.input-range-cyan::-moz-range-track { background-color: cyan !important; }
.input-range-cyan::-ms-track { background-color: cyan !important; }
.input-range-cyan::-webkit-slider-thumb { background-color: cyan !important; }
.input-range-cyan::-moz-range-thumb { background-color: cyan !important; }
.input-range-cyan::-ms-thumb { background-color: cyan !important; }

.input-range-darkblue::-webkit-slider-runnable-track { background-color: darkblue !important; }
.input-range-darkblue::-moz-range-track { background-color: darkblue !important; }
.input-range-darkblue::-ms-track { background-color: darkblue !important; }
.input-range-darkblue::-webkit-slider-thumb { background-color: darkblue !important; }
.input-range-darkblue::-moz-range-thumb { background-color: darkblue !important; }
.input-range-darkblue::-ms-thumb { background-color: darkblue !important; }

.input-range-darkcyan::-webkit-slider-runnable-track { background-color: darkcyan !important; }
.input-range-darkcyan::-moz-range-track { background-color: darkcyan !important; }
.input-range-darkcyan::-ms-track { background-color: darkcyan !important; }
.input-range-darkcyan::-webkit-slider-thumb { background-color: darkcyan !important; }
.input-range-darkcyan::-moz-range-thumb { background-color: darkcyan !important; }
.input-range-darkcyan::-ms-thumb { background-color: darkcyan !important; }

.input-range-darkgrey::-webkit-slider-runnable-track { background-color: darkgrey !important; }
.input-range-darkgrey::-moz-range-track { background-color: darkgrey !important; }
.input-range-darkgrey::-ms-track { background-color: darkgrey !important; }
.input-range-darkgrey::-webkit-slider-thumb { background-color: darkgrey !important; }
.input-range-darkgrey::-moz-range-thumb { background-color: darkgrey !important; }
.input-range-darkgrey::-ms-thumb { background-color: darkgrey !important; }

.input-range-darkgreen::-webkit-slider-runnable-track { background-color: darkgreen !important; }
.input-range-darkgreen::-moz-range-track { background-color: darkgreen !important; }
.input-range-darkgreen::-ms-track { background-color: darkgreen !important; }
.input-range-darkgreen::-webkit-slider-thumb { background-color: darkgreen !important; }
.input-range-darkgreen::-moz-range-thumb { background-color: darkgreen !important; }
.input-range-darkgreen::-ms-thumb { background-color: darkgreen !important; }

.input-range-darkkhaki::-webkit-slider-runnable-track { background-color: darkkhaki !important; }
.input-range-darkkhaki::-moz-range-track { background-color: darkkhaki !important; }
.input-range-darkkhaki::-ms-track { background-color: darkkhaki !important; }
.input-range-darkkhaki::-webkit-slider-thumb { background-color: darkkhaki !important; }
.input-range-darkkhaki::-moz-range-thumb { background-color: darkkhaki !important; }
.input-range-darkkhaki::-ms-thumb { background-color: darkkhaki !important; }

.input-range-darkmagenta::-webkit-slider-runnable-track { background-color: darkmagenta !important; }
.input-range-darkmagenta::-moz-range-track { background-color: darkmagenta !important; }
.input-range-darkmagenta::-ms-track { background-color: darkmagenta !important; }
.input-range-darkmagenta::-webkit-slider-thumb { background-color: darkmagenta !important; }
.input-range-darkmagenta::-moz-range-thumb { background-color: darkmagenta !important; }
.input-range-darkmagenta::-ms-thumb { background-color: darkmagenta !important; }

.input-range-darkolivegreen::-webkit-slider-runnable-track { background-color: darkolivegreen !important; }
.input-range-darkolivegreen::-moz-range-track { background-color: darkolivegreen !important; }
.input-range-darkolivegreen::-ms-track { background-color: darkolivegreen !important; }
.input-range-darkolivegreen::-webkit-slider-thumb { background-color: darkolivegreen !important; }
.input-range-darkolivegreen::-moz-range-thumb { background-color: darkolivegreen !important; }
.input-range-darkolivegreen::-ms-thumb { background-color: darkolivegreen !important; }

.input-range-darkorange::-webkit-slider-runnable-track { background-color: darkorange !important; }
.input-range-darkorange::-moz-range-track { background-color: darkorange !important; }
.input-range-darkorange::-ms-track { background-color: darkorange !important; }
.input-range-darkorange::-webkit-slider-thumb { background-color: darkorange !important; }
.input-range-darkorange::-moz-range-thumb { background-color: darkorange !important; }
.input-range-darkorange::-ms-thumb { background-color: darkorange !important; }

.input-range-darkorchid::-webkit-slider-runnable-track { background-color: darkorchid !important; }
.input-range-darkorchid::-moz-range-track { background-color: darkorchid !important; }
.input-range-darkorchid::-ms-track { background-color: darkorchid !important; }
.input-range-darkorchid::-webkit-slider-thumb { background-color: darkorchid !important; }
.input-range-darkorchid::-moz-range-thumb { background-color: darkorchid !important; }
.input-range-darkorchid::-ms-thumb { background-color: darkorchid !important; }

.input-range-darkred::-webkit-slider-runnable-track { background-color: darkred !important; }
.input-range-darkred::-moz-range-track { background-color: darkred !important; }
.input-range-darkred::-ms-track { background-color: darkred !important; }
.input-range-darkred::-webkit-slider-thumb { background-color: darkred !important; }
.input-range-darkred::-moz-range-thumb { background-color: darkred !important; }
.input-range-darkred::-ms-thumb { background-color: darkred !important; }

.input-range-darksalmon::-webkit-slider-runnable-track { background-color: darksalmon !important; }
.input-range-darksalmon::-moz-range-track { background-color: darksalmon !important; }
.input-range-darksalmon::-ms-track { background-color: darksalmon !important; }
.input-range-darksalmon::-webkit-slider-thumb { background-color: darksalmon !important; }
.input-range-darksalmon::-moz-range-thumb { background-color: darksalmon !important; }
.input-range-darksalmon::-ms-thumb { background-color: darksalmon !important; }

.input-range-darkviolet::-webkit-slider-runnable-track { background-color: darkviolet !important; }
.input-range-darkviolet::-moz-range-track { background-color: darkviolet !important; }
.input-range-darkviolet::-ms-track { background-color: darkviolet !important; }
.input-range-darkviolet::-webkit-slider-thumb { background-color: darkviolet !important; }
.input-range-darkviolet::-moz-range-thumb { background-color: darkviolet !important; }
.input-range-darkviolet::-ms-thumb { background-color: darkviolet !important; }

.input-range-fuchsia::-webkit-slider-runnable-track { background-color: fuchsia !important; }
.input-range-fuchsia::-moz-range-track { background-color: fuchsia !important; }
.input-range-fuchsia::-ms-track { background-color: fuchsia !important; }
.input-range-fuchsia::-webkit-slider-thumb { background-color: fuchsia !important; }
.input-range-fuchsia::-moz-range-thumb { background-color: fuchsia !important; }
.input-range-fuchsia::-ms-thumb { background-color: fuchsia !important; }

.input-range-gold::-webkit-slider-runnable-track { background-color: gold !important; }
.input-range-gold::-moz-range-track { background-color: gold !important; }
.input-range-gold::-ms-track { background-color: gold !important; }
.input-range-gold::-webkit-slider-thumb { background-color: gold !important; }
.input-range-gold::-moz-range-thumb { background-color: gold !important; }
.input-range-gold::-ms-thumb { background-color: gold !important; }

.input-range-green::-webkit-slider-runnable-track { background-color: green !important; }
.input-range-green::-moz-range-track { background-color: green !important; }
.input-range-green::-ms-track { background-color: green !important; }
.input-range-green::-webkit-slider-thumb { background-color: green !important; }
.input-range-green::-moz-range-thumb { background-color: green !important; }
.input-range-green::-ms-thumb { background-color: green !important; }

.input-range-indigo::-webkit-slider-runnable-track { background-color: indigo !important; }
.input-range-indigo::-moz-range-track { background-color: indigo !important; }
.input-range-indigo::-ms-track { background-color: indigo !important; }
.input-range-indigo::-webkit-slider-thumb { background-color: indigo !important; }
.input-range-indigo::-moz-range-thumb { background-color: indigo !important; }
.input-range-indigo::-ms-thumb { background-color: indigo !important; }

.input-range-khaki::-webkit-slider-runnable-track { background-color: khaki !important; }
.input-range-khaki::-moz-range-track { background-color: khaki !important; }
.input-range-khaki::-ms-track { background-color: khaki !important; }
.input-range-khaki::-webkit-slider-thumb { background-color: khaki !important; }
.input-range-khaki::-moz-range-thumb { background-color: khaki !important; }
.input-range-khaki::-ms-thumb { background-color: khaki !important; }

.input-range-lightblue::-webkit-slider-runnable-track { background-color: lightblue !important; }
.input-range-lightblue::-moz-range-track { background-color: lightblue !important; }
.input-range-lightblue::-ms-track { background-color: lightblue !important; }
.input-range-lightblue::-webkit-slider-thumb { background-color: lightblue !important; }
.input-range-lightblue::-moz-range-thumb { background-color: lightblue !important; }
.input-range-lightblue::-ms-thumb { background-color: lightblue !important; }

.input-range-lightcyan::-webkit-slider-runnable-track { background-color: lightcyan !important; }
.input-range-lightcyan::-moz-range-track { background-color: lightcyan !important; }
.input-range-lightcyan::-ms-track { background-color: lightcyan !important; }
.input-range-lightcyan::-webkit-slider-thumb { background-color: lightcyan !important; }
.input-range-lightcyan::-moz-range-thumb { background-color: lightcyan !important; }
.input-range-lightcyan::-ms-thumb { background-color: lightcyan !important; }

.input-range-lightgreen::-webkit-slider-runnable-track { background-color: lightgreen !important; }
.input-range-lightgreen::-moz-range-track { background-color: lightgreen !important; }
.input-range-lightgreen::-ms-track { background-color: lightgreen !important; }
.input-range-lightgreen::-webkit-slider-thumb { background-color: lightgreen !important; }
.input-range-lightgreen::-moz-range-thumb { background-color: lightgreen !important; }
.input-range-lightgreen::-ms-thumb { background-color: lightgreen !important; }

.input-range-lightgrey::-webkit-slider-runnable-track { background-color: lightgrey !important; }
.input-range-lightgrey::-moz-range-track { background-color: lightgrey !important; }
.input-range-lightgrey::-ms-track { background-color: lightgrey !important; }
.input-range-lightgrey::-webkit-slider-thumb { background-color: lightgrey !important; }
.input-range-lightgrey::-moz-range-thumb { background-color: lightgrey !important; }
.input-range-lightgrey::-ms-thumb { background-color: lightgrey !important; }

.input-range-lightpink::-webkit-slider-runnable-track { background-color: lightpink !important; }
.input-range-lightpink::-moz-range-track { background-color: lightpink !important; }
.input-range-lightpink::-ms-track { background-color: lightpink !important; }
.input-range-lightpink::-webkit-slider-thumb { background-color: lightpink !important; }
.input-range-lightpink::-moz-range-thumb { background-color: lightpink !important; }
.input-range-lightpink::-ms-thumb { background-color: lightpink !important; }

.input-range-lightyellow::-webkit-slider-runnable-track { background-color: lightyellow !important; }
.input-range-lightyellow::-moz-range-track { background-color: lightyellow !important; }
.input-range-lightyellow::-ms-track { background-color: lightyellow !important; }
.input-range-lightyellow::-webkit-slider-thumb { background-color: lightyellow !important; }
.input-range-lightyellow::-moz-range-thumb { background-color: lightyellow !important; }
.input-range-lightyellow::-ms-thumb { background-color: lightyellow !important; }

.input-range-lime::-webkit-slider-runnable-track { background-color: lime !important; }
.input-range-lime::-moz-range-track { background-color: lime !important; }
.input-range-lime::-ms-track { background-color: lime !important; }
.input-range-lime::-webkit-slider-thumb { background-color: lime !important; }
.input-range-lime::-moz-range-thumb { background-color: lime !important; }
.input-range-lime::-ms-thumb { background-color: lime !important; }

.input-range-magenta::-webkit-slider-runnable-track { background-color: magenta !important; }
.input-range-magenta::-moz-range-track { background-color: magenta !important; }
.input-range-magenta::-ms-track { background-color: magenta !important; }
.input-range-magenta::-webkit-slider-thumb { background-color: magenta !important; }
.input-range-magenta::-moz-range-thumb { background-color: magenta !important; }
.input-range-magenta::-ms-thumb { background-color: magenta !important; }

.input-range-maroon::-webkit-slider-runnable-track { background-color: maroon !important; }
.input-range-maroon::-moz-range-track { background-color: maroon !important; }
.input-range-maroon::-ms-track { background-color: maroon !important; }
.input-range-maroon::-webkit-slider-thumb { background-color: maroon !important; }
.input-range-maroon::-moz-range-thumb { background-color: maroon !important; }
.input-range-maroon::-ms-thumb { background-color: maroon !important; }

.input-range-maroon::-webkit-slider-runnable-track { background-color: maroon !important; }
.input-range-maroon::-moz-range-track { background-color: maroon !important; }
.input-range-maroon::-ms-track { background-color: maroon !important; }
.input-range-maroon::-webkit-slider-thumb { background-color: maroon !important; }
.input-range-maroon::-moz-range-thumb { background-color: maroon !important; }
.input-range-maroon::-ms-thumb { background-color: maroon !important; }

.input-range-navy::-webkit-slider-runnable-track { background-color: navy !important; }
.input-range-navy::-moz-range-track { background-color: navy !important; }
.input-range-navy::-ms-track { background-color: navy !important; }
.input-range-navy::-webkit-slider-thumb { background-color: navy !important; }
.input-range-navy::-moz-range-thumb { background-color: navy !important; }
.input-range-navy::-ms-thumb { background-color: navy !important; }

.input-range-olive::-webkit-slider-runnable-track { background-color: olive !important; }
.input-range-olive::-moz-range-track { background-color: olive !important; }
.input-range-olive::-ms-track { background-color: olive !important; }
.input-range-olive::-webkit-slider-thumb { background-color: olive !important; }
.input-range-olive::-moz-range-thumb { background-color: olive !important; }
.input-range-olive::-ms-thumb { background-color: olive !important; }

.input-range-orange::-webkit-slider-runnable-track { background-color: orange !important; }
.input-range-orange::-moz-range-track { background-color: orange !important; }
.input-range-orange::-ms-track { background-color: orange !important; }
.input-range-orange::-webkit-slider-thumb { background-color: orange !important; }
.input-range-orange::-moz-range-thumb { background-color: orange !important; }
.input-range-orange::-ms-thumb { background-color: orange !important; }

.input-range-pink::-webkit-slider-runnable-track { background-color: pink !important; }
.input-range-pink::-moz-range-track { background-color: pink !important; }
.input-range-pink::-ms-track { background-color: pink !important; }
.input-range-pink::-webkit-slider-thumb { background-color: pink !important; }
.input-range-pink::-moz-range-thumb { background-color: pink !important; }
.input-range-pink::-ms-thumb { background-color: pink !important; }

.input-range-purple::-webkit-slider-runnable-track { background-color: purple !important; }
.input-range-purple::-moz-range-track { background-color: purple !important; }
.input-range-purple::-ms-track { background-color: purple !important; }
.input-range-purple::-webkit-slider-thumb { background-color: purple !important; }
.input-range-purple::-moz-range-thumb { background-color: purple !important; }
.input-range-purple::-ms-thumb { background-color: purple !important; }

.input-range-violet::-webkit-slider-runnable-track { background-color: violet !important; }
.input-range-violet::-moz-range-track { background-color: violet !important; }
.input-range-violet::-ms-track { background-color: violet !important; }
.input-range-violet::-webkit-slider-thumb { background-color: violet !important; }
.input-range-violet::-moz-range-thumb { background-color: violet !important; }
.input-range-violet::-ms-thumb { background-color: violet !important; }

.input-range-red::-webkit-slider-runnable-track { background-color: red !important; }
.input-range-red::-moz-range-track { background-color: red !important; }
.input-range-red::-ms-track { background-color: red !important; }
.input-range-red::-webkit-slider-thumb { background-color: red !important; }
.input-range-red::-moz-range-thumb { background-color: red !important; }
.input-range-red::-ms-thumb { background-color: red !important; }

.input-range-silver::-webkit-slider-runnable-track { background-color: silver !important; }
.input-range-silver::-moz-range-track { background-color: silver !important; }
.input-range-silver::-ms-track { background-color: silver !important; }
.input-range-silver::-webkit-slider-thumb { background-color: silver !important; }
.input-range-silver::-moz-range-thumb { background-color: silver !important; }
.input-range-silver::-ms-thumb { background-color: silver !important; }

.input-range-white::-webkit-slider-runnable-track { background-color: white !important; }
.input-range-white::-moz-range-track { background-color: white !important; }
.input-range-white::-ms-track { background-color: white !important; }
.input-range-white::-webkit-slider-thumb { background-color: white !important; }
.input-range-white::-moz-range-thumb { background-color: white !important; }
.input-range-white::-ms-thumb { background-color: white !important; }

.input-range-yellow::-webkit-slider-runnable-track { background-color: yellow !important; }
.input-range-yellow::-moz-range-track { background-color: yellow !important; }
.input-range-yellow::-ms-track { background-color: yellow !important; }
.input-range-yellow::-webkit-slider-thumb { background-color: yellow !important; }
.input-range-yellow::-moz-range-thumb { background-color: yellow !important; }
.input-range-yellow::-ms-thumb { background-color: yellow !important; }
