
.LensPlayer {
    display: inline-flex;

    border: 2px solid rgba(0, 0, 0, 0.3);

    background: black;

    position: relative;

    overflow: hidden;

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);

    border-radius: 5px;
}

.LensPlayer .LensReactPlayer {
    display: block;

    width: 100%;
    height: 100%;

    background: purple;
}

.LensPlayer .LensReactPlayer video {
    display: block;

    outline: none;

    width: 100%;
    height: 100%;

    background: black;
}

.hidden,
[hidden] {
    display: none !important;
}

.disabled,
[disabled] {
    opacity: 0.5 !important;
    pointer-events: none !important;
}
