
@keyframes LensXRay-pending {
    from {
        opacity: 0.6;
    }
    to {
        opacity: 0.2;
    }
}


.LensXRay {
    position: absolute;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 1;

    pointer-events: none;

    font-family: helvetica neue;
}

.LensXRay-pending {
    animation: LensXRay-pending 500ms infinite alternate;
    pointer-events: none !important;
    cursor: not-allowed !important;
}

.LensXRay > * {
    pointer-events: all;
    box-sizing: border-box;
}

.LensXRay > img {
    padding: 3px;
}

.LensXRayAction {
    position: absolute;

    right: 5px;
    top: 0px;

    margin: 0;
    padding: 7px;

    width: 50px;
    height: 50px;

    color: white;

    border-radius: 50%;

    user-select: none;

    transform: scale(1);
    transform-origin: right top;

    opacity: 1;

    transition: transform 50ms ease;
}

.LensXRayAction:hover {
    cursor: pointer;

    margin-top: 1px;

    transform: scale(1.02);
}

.LensXRayOverlay {
    position: fixed;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    width: 100vw;
    height: 100vh;

    background: rgba(0, 0, 0, 0.9);
    color: white;

    padding: 40px;

    overflow: hidden;
    overflow-y: scroll;

    -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch;
}

.LensXRayOverlay-header {
    padding-bottom: 20px;
}

.LensXRayOverlay-title {
    padding-bottom: 5px;

    font-size: 3rem;

    color: rgba(255, 255, 255, 0.2);

    font-weight: 200;
}

.LensXRayOverlay-title span:nth-child(2) {
    color: gold;
}

.LensXRayOverlay-description {
    padding-bottom: 20px;

    font-size: 1.2rem;

    color: rgba(255, 255, 255, 0.2);

    font-weight: 200;
}

.LensXRayTopResults {
    display: block;

    padding-bottom: 30vh;
}

.LensXRayTopResult {
    display: inline-block;

    flex: 0;

    width: 120px;
    height: 120px;

    min-width: 120px;
    min-height: 180px;

    margin-right: 25px;
    margin-bottom: 25px;

    background-color: rgba(255, 255, 255, 0.1);
    background-size: cover;
    background-position: center center;

    border-radius: 5px;

    cursor: pointer;

    border: 1px solid gold;
    box-shadow: 0 0 5px gold;

    transform: scale(1);

    position: relative;

    transition: transform 50ms;
}

.LensXRayTopResult:hover {
    box-shadow: 0 0 15px gold;

    transform: scale(1.02);
}

.LensXRayTopResult-details {

}

.LensXRayTopResult-category {
    position: absolute;

    overflow: visible;
    left: -5px;
    top: 10px;

    padding: 3px 10px;

    background: rgba(255, 215, 0, 1);
    color: rgba(255, 255, 255, 1);

    font-size: 11px;

    text-transform: uppercase;
    text-align: center;

    border-radius: 2px;

    box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);

    letter-spacing: 0.5px;

    font-weight: 400;
}

.LensXRayTopResult-price {
    position: absolute;

    display: inline-block;

    bottom: 15px;
    right: 20px;

    padding: 3px 10px;

    background: rgba(255, 215, 0, 1);
    color: rgba(0, 0, 0, 1);

    font-size: 11px;

    text-transform: uppercase;
    text-align: center;

    transform: rotate(-5deg);
    transform-origin: 0 0;

    border-radius: 10px;

    box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);

    font-weight: bold;

    letter-spacing: 0.5px;
}

.LensXRayTopResult-preview {
    position: fixed;

    bottom: 40px;
    left: 40px;

    height: 40vh;
    width: auto;

    border: 1px solid rgba(255, 215, 0, 1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);

    border-radius: 2px;

    z-index: 1000000000;

    background: black;

    min-width: 5vw;
}

.LensXRayOverlayAction-close {
    display: flex;

    justify-content: center;
    align-items: center;

    line-height: 1;

    position: fixed;

    top: 20px;
    right: 20px;

    background: rgba(255, 215, 0, 1);

    border-radius: 50%;

    padding: 10px;

    width: 50px;
    height: 50px;

    color: black;

    font-size: 40px;
    font-weight: 200;

    cursor: pointer;

    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    z-index: 100000;
}

.LensXRayOverlayAction-close span {
    display: block;

    height: 48px;
}

.LensXRayOverlayAction-close:hover {
    color: red;

    font-weight: bold;
}

@media only screen and (max-width: 600px) {
    .LensXRayTopResult-preview {
        display: none;
    }
}
